/*===== [MUI] =====*/
import { Grid2, Typography, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'; 
/*=================*/

/*===== [Reacy] =====*/
import { Fragment } from 'react';
/*===================*/

/*===== [Icons] =====*/
import LaptopIcon from '@mui/icons-material/Laptop';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RouteIcon from '@mui/icons-material/Route';
/*===================*/

/*===== [CSS] =====*/
import './servicespage.css'
/*=================*/

/*===== [Assets] =====*/
import ServiceImage from '../../assets/images/services_image.png'
/*===== [Assets] =====*/

/*===== [React-Scroll] =====*/
import { Element } from 'react-scroll'
/*=========================*/

const ServicesPage = () => {
    return(
        <Element name="Services">
            <Grid2 className="servicesGrid">
                <Grid2 >
                    <Typography variant='h4' className='h4Styling' sx={{ marginBottom: "0px"}}>
                        SERVICES
                    </Typography>
                </Grid2>
                <Grid2 className="servicesContentGrid">
                    <Grid2 className="leftGrid">
                        <Typography variant='h4'>
                            B2T (Business to Tech to Business)
                        </Typography>
                        <Typography variant='body1' id="btbIntroText">
                            We specialize in B2T (Business to Tech to Business), a unique approach that ensures smooth collaboration and clear communication between your
                            business and technical teams. Our proven three-pillar strategy focuses on bridging the gap between business needs and technical execution,
                            helping companies deliver projects efficiently and effectively.
                        </Typography>
                        <Typography variant='h5'>
                            Our Three-Pillar Approach:
                        </Typography>
                        <Typography>
                            <nav aria-label="main mailbox folders">
                                <List>
                                    <ListItem disablePadding>
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <LaptopIcon className="serviceIconHero"/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Fragment>
                                                        <Typography variant="h6">
                                                            Software Engineering
                                                        </Typography>
                                                    </Fragment>
                                                }
                                                secondary={
                                                    <Fragment>
                                                        <Typography
                                                            component="span"
                                                            sx={{ color: 'black', display: 'inline' }}
                                                        >
                                                            Our software engineers assess the technical feasibility of your business requirements.
                                                            They collaborate with UI/UX designers to ensure that the technical solutions align with the desired business outcomes,
                                                            timeline, and any development constraints. This ensures a unified understanding across all teams, keeping everyone on track.
                                                        </Typography>
                                                    </Fragment>
                                                }
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <DesignServicesIcon className="second-icon" />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Fragment>
                                                        <Typography variant="h6">
                                                            UI/UX Development
                                                        </Typography>
                                                    </Fragment>
                                                }
                                                secondary={
                                                    <Fragment>
                                                        <Typography
                                                            component="span"
                                                            sx={{ color: 'black', display: 'inline' }}
                                                        >
                                                            Our UI/UX experts transform business needs into intuitive, user-focused designs. They outline user journeys
                                                            and create detailed interface designs, providing your tech team with a clear roadmap to build the product.
                                                            This minimizes miscommunication, ensuring the end result meets both business goals and user expectations.
                                                        </Typography>
                                                    </Fragment>
                                                }
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton>
                                            <ListItemIcon >
                                                <DonutLargeIcon className="serviceIconHero"/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Fragment>
                                                        <Typography variant="h6">
                                                            Product Management/Product Ownership
                                                        </Typography>
                                                    </Fragment>
                                                }
                                                secondary={
                                                    <Fragment>
                                                        <Typography
                                                            component="span"
                                                            sx={{ color: 'black', display: 'inline' }}
                                                        >
                                                            Our project managers and product owners coordinate and manage priorities, making sure that timelines are realistic and
                                                            aligned with business objectives. They handle any necessary adjustments to deadlines or scope, ensuring that both business
                                                            and tech teams maintain clear, aligned expectations throughout the project.
                                                        </Typography>
                                                    </Fragment>
                                                }
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </nav>
                        </Typography>
                    </Grid2>
                    <Grid2 className="rightGrid">
                        <img src={ServiceImage} alt="services image" />
                    </Grid2>
                </Grid2>
                <Grid2 id="servicesText_1">
                    <Typography variant='body1'>
                        Beyond connecting you with top-tier experts, we're committed to optimizing your entire software development lifecycle. Our in-depth analysis includes
                        your onboarding, task management, source control workflows, and agile sprint practices. We'll implement best practices to streamline your processes
                        and maximize your team's efficiency and productivity. By focusing on seamless communication and practical execution, we ensure that your business requirements
                        are accurately translated into technical solutions. This approach minimizes common pitfalls such as misinterpretation, delays, and budget overruns.
                        With Visagie & Co's B2T model, you can confidently meet deadlines, reduce costs, and deliver high-quality projects that meet both business and technical standards.
                    </Typography>
                </Grid2>
                <Grid2 className="serviceBenifitsGrid">
                    <Typography variant='h4'>
                        WHAT ARE THE BENEFITS?
                    </Typography>
                    <Grid2 id="serviceBenifitsGrid">
                        <Grid2>
                            <QuestionAnswerIcon />
                            <Typography variant='h5'>
                                Improved communication
                            </Typography>
                            <Typography variant='body1'>
                                At the core of our B2T approach is the commitment to fostering clear and seamless communication between business and technology teams.
                                Often, the disconnect between what business envisions and what tech teams execute leads to misalignment, delays, and increased costs.
                                At Visagie & Co, we act as the bridge to close this gap.
                            </Typography>
                        </Grid2>
                        <Grid2>
                            <DirectionsRunIcon />
                            <Typography variant='h5'>
                                Faster development
                            </Typography>
                            <Typography variant='body1'>
                                Our B2T approach is designed to streamline development, ensuring that projects are delivered faster and more efficiently.
                                By aligning business requirements with technical capabilities right from the start, we reduce time spent on clarifications,
                                revisions, and misinterpretations.
                            </Typography>
                        </Grid2>
                        <Grid2>
                            <AttachMoneyIcon />
                            <Typography variant='h5'>
                                Cost-Effectiveness
                            </Typography>
                            <Typography variant='body1'>
                                We focus on delivering cost-effective solutions by ensuring that projects are developed efficiently and within budget.
                                Our B2T approach streamlines the entire development process, reducing unnecessary delays and cutting down on development expenses.
                            </Typography>
                        </Grid2>
                        <Grid2>
                            <RouteIcon />
                            <Typography variant='h5'>
                                Expert Guidance
                            </Typography>
                            <Typography variant='body1'>
                                We provide expert guidance through a team of highly trained consultants who specialize in closing the gap between business and technology.
                                Our consultants possess a deep understanding of both business processes and technical requirements, enabling them to seamlessly
                                translate needs and expectations between teams.
                            </Typography>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Grid2>
        </Element>

    );
}

export default ServicesPage;