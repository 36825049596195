/*===== [FORMIK] =====*/
import { useFormik } from 'formik';
import * as yup from 'yup';
/*===================*/

/*===== [MUI] =====*/
import { Grid2, TextField,  Button } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
/*=================*/

/*===== [CSS] =====*/
import styles from './form.module.css';
/*=================*/

/*===== [Services] =====*/
import { postData } from '../../Services/api';
/*======================*/

import { useState } from 'react';

const validationSchema = yup.object({
    client_name: yup
        .string('Enter your name')
        .required('Please enter your name'),
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    client_company: yup
        .string('Enter your company')
        .required('Please enter your comapny name'),
    message: yup
        .string('Enter a message')
        .required('Please enter your message'),
    
});

function GradientCircularProgress() {
    return (
      <>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#484848" />
              <stop offset="100%" stopColor="#000" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress size={20} sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
      </>
    );
}

const FormComponent = () => {
    const [isSubmitting, setSubmitting] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);

    const formik = useFormik({
        initialValues: {
            client_name: '',
            email: '',
            contact_number: '',
            client_company: '',
            message: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);  // Show the progress circle
            setSubmitted(false); 

            let data = {
                route: "forms",
                data: values,
            };

            const response = postData(data);
            if(response){
                setSubmitted(true);
                

                setTimeout(() => {
                    setSubmitted(false); 
                    resetForm();
                }, 3000);
            }else{

            }
            setSubmitting(false); 
        },
    });

    return (
        <Grid2>
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    required
                    id="client_name"
                    name="client_name"
                    label="Name & Surname"
                    className={styles.inputStyle}
                    value={formik.values.client_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.client_name && Boolean(formik.errors.client_name)}
                    helperText={formik.touched.client_name && formik.errors.client_name}
                    // className={styles.textFields}
                    // sx={{ width: "48%", marginRight: "30px !important", marginBottom: "10px !important" }}
                />
                <TextField
                    required
                    id="email"
                    name="email"
                    label="Email"
                    className={styles.inputStyle}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    // sx={{ width: "48%", marginBottom: "10px !important" }}
                />
                <TextField
                    required
                    id="client_company"
                    name="client_company"
                    label="Company"
                    className={styles.inputStyle}
                    value={formik.values.client_company}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.client_company && Boolean(formik.errors.client_company)}
                    helperText={formik.touched.client_company && formik.errors.client_company}
                    // sx={{ width: "48%", marginRight: "30px !important", marginBottom: "10px !important"  }}
                />
                <TextField
                    required
                    id="contact_number"
                    name="contact_number"
                    label="Contact #"
                    className={styles.inputStyle}
                    value={formik.values.contact_number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.contact_number && Boolean(formik.errors.contact_number)}
                    helperText={formik.touched.contact_number && formik.errors.contact_number}
                    // sx={{ width: "48%", marginBottom: "10px !important"  }}
                />

                <TextField
                    fullWidth
                    id="message"
                    label="Message"
                    required
                    multiline
                    rows={6}
                    defaultValue=""
                    className={styles.textAreaStyle}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.message && Boolean(formik.errors.message)}
                    helperText={formik.touched.message && formik.errors.message}
                />
                <Button variant="contained" type="submit" className={styles.formButton} disabled={isSubmitting || isSubmitted}>
                    {isSubmitting ? (
                        <GradientCircularProgress />
                    ) : isSubmitted ? (
                        "Form Submitted, Thank You" 
                    ) : (
                        "Send" 
                    )}
                </Button>

                
            </form>
        </Grid2>
    );
};

export default FormComponent;
