/*===== [MUI] =====*/
import { Grid2, Typography } from "@mui/material";
/*=================*/

/*===== [CSS] =====*/
import styles from './footer.module.css';
/*=================*/

/*===== [Assets] =====*/
import Logo from "../../assets/images/Logo.png"
/*====================*/

const FooterLayout = () => {
    const currentYear = new Date().getFullYear();
    return(
        <Grid2 className={ styles.footerGrid }>
            <Grid2 size={12}>
                <Typography sx={{ fontFamily: "Cinzel !important" }}>
                    COPYRIGHT &#169; VISAGIE & CO | 2023 - {currentYear}
                </Typography>
                <img src={Logo} alt="company logo" />
                <Typography sx={{ fontFamily: "Cinzel !important" }}>
                    YOUR VISION, OUR MISSION
                </Typography>
                
            </Grid2>
            <Grid2 size={12}>
                <Typography sx={{ fontFamily: "Cinzel !important", fontSize: "10px" }}>
                    Visagie & Brothers (PTY) LTD, trading as Visagie & Co. T's & C's apply. All rights reserved
                </Typography>
            </Grid2>
           
        </Grid2>
    );
}

export default FooterLayout;