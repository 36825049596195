/*===== [MUI] =====*/
import { Grid2, Typography, Box } from "@mui/material";
/*=================*/

/*===== [CSS] =====*/
import './missionpage.css'
/*=================*/

/*===== [Assets] =====*/
import MissionImage from "../../assets/images/mission_image.png";
/*====================*/

/*===== [React-Scroll] =====*/
import { Element } from 'react-scroll'
/*=========================*/

const MissionPage = () => {
    return(
        <Element name="Mission">
            <Grid2 className="missionGrid">
                <Typography
                    variant="h4"
                    className="h4Styling"
                >
                    OUR MISSION
                </Typography>
                <Grid2 className="missionContentGrid">
                    <Grid2 className="missionImage">
                        <img src={MissionImage} alt="Image of the mission" />
                    </Grid2>
                    <Grid2 className="missionContent">
                        <Box>
                            <Typography variant="h4">
                                THE MISSION:
                            </Typography>
                            <Typography>
                                Visagie & Co is dedicated to streamlining the software development lifecycle by bridging the communication gap between
                                business and technology teams, utilising the strengths of AGILE.
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="h4">
                                THE VISION:
                            </Typography>
                            <Typography>
                                We aspire to be the industry leader in optimising development processes, empowering businesses to achieve faster time-to-market and lower project costs.
                            </Typography>
                        </Box>
                        <Box>
                            <Typography>
                                We want to empower the growth and success of businesses, whether they are just starting their journey or seeking expansion opportunities.
                                We are dedicated to providing comprehensive consulting services that guide entrepreneurs and established companies alike towards achieving
                                their goals. With a focus on innovation, strategic thinking, and unwavering support,
                                we aim to be the catalyst for our clients' entrepreneurial dreams, helping them navigate the complexities of business with confidence and expertise.
                            </Typography>
                        </Box>
                    </Grid2>
                </Grid2>

            </Grid2>
        </Element>

    );
}

export default MissionPage;