/*===== [MUI] =====*/
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
/*===================*/

/*===== [Icons] =====*/
import AdbIcon from '@mui/icons-material/Adb';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
/*===================*/

/*===== [React] =====*/
import { useState } from 'react';
/*===================*/

/*===== [Assets] =====*/
import Logo from "../../assets/images/Logo.png";
/*====================*/

/*===== [React-Scroll] =====*/
import { Link } from 'react-scroll';
/*=========================*/

/*===== [React-Router-Dom] =====*/
import { Link as RouterLink }  from 'react-router-dom';
/*==============================*/

/*===== [CSS] =====*/
import "./navbar.css"
/*=================*/

import SwipeableTemporaryDrawer from '../NavDrawer';

const pages = ['Home', 'Mission', 'Services', 'Contact'];//, 'Blog'];

function Navbar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#000'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters > 
            <img 
                src={Logo} 
                alt="Visagie and Co logo." 
                className="logoNav"
            />

            {/* Mobile */}
            {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{ display: { xs: 'block', md: 'none' } }}
                >
                    {pages.map((page) => (
                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                        <Typography sx={{ textAlign: 'center' }}>{page}</Typography>
                    </MenuItem>
                    ))}
                </Menu>
            </Box> */}
                
            {/* Desktop */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'end'}}>
                {pages.map((page) => (
                    page === 'Home' ? (
                        <Button
                            component={RouterLink}
                            to="/"
                            key={page}
                            sx={{ my: 2, color: 'white', display: 'block', textDecoration: 'none' }}
                        >
                            {page}
                        </Button>
                    ) : (
                        <Link to={page} key={page} smooth={true} duration={500} offset={-60}>
                            <Button
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page}
                            </Button>
                        </Link>
                    )
                ))}

                <Button
                    component={RouterLink}
                    to="/careers"
                    sx={{ my: 2, color: 'white', display: 'block', textDecoration: 'none' }}
                >
                    Careers
                </Button>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'end'}}>
              <SwipeableTemporaryDrawer />
            </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
