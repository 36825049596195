import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;
const token = process.env.REACT_APP_API_KEY;

export const postData = async (data) => {
  try {
    const route = data.route;
    const response = await axios.post(`${baseURL}/${route}`, data.data,{
      headers: {
        'X-API-KEY': token,
      },
    });

    if (response.data.message === "success") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    if (error.response) {
      // Error response from server
      console.error('Error posting data:', error.response.data);
      throw error.response.data; // or return error.response.data
    } else if (error.request) {
      // No response from server
      console.error('No response from server:', error.request);
      throw 'No response from server';
    } else {
      // Other errors
      console.error('Error posting data:', error.message);
      throw error.message;
    }
  }
};

export const getData = async (data) => {
  try {
    const route = data.route;
    const response = await axios.get(`${baseURL}/${route}`, {
      headers: {
        'X-API-KEY': token,
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      // Error response from server
      console.error('Error posting data:', error.response.data);
      throw error.response.data; // or return error.response.data
    } else if (error.request) {
      // No response from server
      console.error('No response from server:', error.request);
      throw 'No response from server';
    } else {
      // Other errors
      console.error('Error posting data:', error.message);
      throw error.message;
    }
  }
};