/*===== [CSS] =====*/
import './App.css';
/*=================*/

/*===== [MUI] =====*/
import { Grid2 } from '@mui/material'; 
/*=================*/

/*===== [Pages] =====*/
import HomePage from "./Pages/Home"
import MissionPage from './Pages/Mission';
import ServicesPage from './Pages/Services';
import ContactPage from './Pages/Contact';
import CareersPage from "./Pages/Careers";
/*===================*/

/*===== [Layouts] =====*/
import FooterLayout from './Layout/Footer';
/*=====================*/

/*===== [Components] =====*/
import Navbar from './components/Navbar';
/*========================*/

/*===== [React-Router-Dom] =====*/
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
/*==============================*/

const Home = () => {
    return(
        <Grid2 className="App">
            <HomePage />
            <MissionPage />
            <ServicesPage />
            <ContactPage />
        </Grid2>
    );
}

function App() {
  return (
      <Router>
        <Navbar />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/careers" element={<CareersPage />} />
            <Route path="/careers/:jobCat" element={<CareersPage />} />
        </Routes>
        <FooterLayout />
      </Router>
  );
}

export default App;
