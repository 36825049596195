/*===== [CSS] =====*/
import styles from "./contactpage.module.css";
/*=================*/

/*===== [MUI] =====*/
import { Grid2, Typography, List, ListItem, ListItemText, Divider, Link } from "@mui/material";
/*=================*/

/*===== [Components] =====*/
import FormComponent from "../../components/Form";
/*=======================*/

/*===== [Icons] =====*/
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
/*===================*/

/*===== [React-Scroll] =====*/
import { Element } from 'react-scroll'
/*=========================*/

const ContactPage = () => {
    return(
        <Element name="Contact">
            <Grid2 container className={ styles.mainGrid } >
                <Grid2 size={12} mb={{xs: 1, md:0}}>
                    <Typography variant="h4" className='h4Styling'>
                        CONTACT
                    </Typography>
                </Grid2>
                <Grid2 size={{xs: 12, md:6}} pl={{xs: 0, md:10}} pr={{xs: 0, md:10}}>
                    <Typography variant="h4" className={ styles.contactHeader } mb={{xs: 2, md:5}}>
                        LET'S GET STARTED
                    </Typography>
                    <FormComponent />
                </Grid2>
                <Grid2 size={{xs: 12, md:6}} pl={{xs: 0, md:10}} pr={{xs: 0, md:10}} align = "center" >
                    <Typography variant="h4" className={ styles.contactHeader } mb={5} mt={{xs:5, md:0}}>
                        CONTACT INFORMATION
                    </Typography>
                    <List dense className={ styles.listStyle }>
                        <ListItem
                            secondaryAction={
                                <Typography>
                                    info@visagieandco.com
                                </Typography>
                            }
                        >
                            <ListItemText
                                primary="Email"
                            />
                        </ListItem>

                        <ListItem
                            secondaryAction={
                                <Typography>
                                    +27 68 323 6736
                                </Typography>
                            }
                        >
                            <ListItemText
                                primary="Tel:"
                            />
                        </ListItem>
                    </List>
                    <Divider sx={{ width: "80%"}}/>
                    <List dense className={ styles.listStyle }>
                        <ListItem
                            secondaryAction={
                                <Typography>
                                    09:00 - 17:00
                                </Typography>
                            }
                        >
                            <ListItemText
                                primary="Monday:"

                            />
                        </ListItem>
                        <ListItem
                            secondaryAction={
                                <Typography>
                                    09:00 - 17:00
                                </Typography>
                            }
                        >
                            <ListItemText
                                primary="Tuesday:"

                            />
                        </ListItem>
                        <ListItem
                            secondaryAction={
                                <Typography>
                                    09:00 - 17:00
                                </Typography>
                            }
                        >
                            <ListItemText
                                primary="Wednesday:"

                            />
                        </ListItem>
                        <ListItem
                            secondaryAction={
                                <Typography>
                                    09:00 - 17:00
                                </Typography>
                            }
                        >
                            <ListItemText
                                primary="Thusrday:"

                            />
                        </ListItem>
                        <ListItem
                            secondaryAction={
                                <Typography>
                                    09:00 - 17:00
                                </Typography>
                            }
                        >
                            <ListItemText
                                primary="Friday:"

                            />
                        </ListItem>
                    </List>
                    <Typography variant="h4" className={ styles.contactHeader } sx={{ marginTop: "1rem"}}>
                        FOLLOW US
                    </Typography>
                    <Grid2 className={ styles.socialIcons }>
                        <Link href="https://www.linkedin.com/company/visagie-and-co/" target="_blank">
                            <LinkedInIcon />
                        </Link>
                        <Link href="https://www.facebook.com/visagieandco" target="_blank">
                            <FacebookIcon />
                        </Link>
                        <Link href="https://www.instagram.com/visagieandco/" target="_blank">
                            <InstagramIcon />
                        </Link>
                        <Link href="https://twitter.com/visagieandco" target="_blank">
                            <XIcon />
                        </Link>
                    </Grid2>
                </Grid2>
            </Grid2>
        </Element>

    );
}

export default ContactPage;