/*===== [MUI] =====*/
import { Grid2, Typography } from "@mui/material";
/*=================*/

/*===== [CSS] =====*/
import './homepage.css'
/*=================*/

/*===== [Assets] =====*/
import Logo from "../../assets/images/Logo.png"
/*====================*/

/*===== [React-Scroll] =====*/
import { Element } from 'react-scroll'
/*=========================*/

/*===== [React-Helmet] =====*/
import { Helmet } from "react-helmet";
/*=========================*/

const HomePage = () => {
    const ogData = {
        title: 'Visagie & Co Home',
        description: 'Visagie & Co is dedicated to streamlining the software development lifecycle by bridging the communication gap between business and technology teams',
        url: 'https://visagieandco.com/',
        image: 'https://visagieandco.com/static/media/Logo.7b925ebfb6b58b3f1305.png',
        siteName: 'Visagie & Co | Your Vision, Our Mission',
    }

    return(
        <Element name="Home">
            <Helmet>
                <meta property="og:title" content={ogData.title}/>
                <meta property="og:description" content={ogData.description}/>
                <meta property="og:url" content={ogData.url}/>
                <meta property="og:image" content={ogData.image}/>
                <meta property="og:site_name" content={ogData.siteName}/>
                <meta name="description" content={ogData.description}/>
            </Helmet>
            <Grid2>
                <Grid2 className="mainGrid">
                    <img
                        src={Logo}
                        alt="Visagie & Co Logo"
                        className="logo"
                    />
                    <Typography variant="h1">
                        Visagie & Co
                    </Typography>
                </Grid2>
                <Grid2 sx={{ paddingBottom: "2em"}}>
                    <Typography
                        variant="h4"
                        className="h4Styling"
                    >
                        YOUR VISION
                    </Typography>
                </Grid2>
            </Grid2>
        </Element>

        
    );
}

export default HomePage;