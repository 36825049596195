/*===== [MUI] =====*/
import { Chip, Grid2, Typography, Button, Divider } from "@mui/material";
/*=================*/

/*===== [React Router] =====*/
import { Link, useParams } from "react-router-dom";
/*=================*/

/*===== [CSS] =====*/
import styles from "./careers.module.css";
/*=================*/

/*===== [API] =====*/
import { getData } from "../../Services/api";
/*================*/

/*===== [Datasets] =====*/
// import jobCategories from '../../Datasets/jobCategories.json';
// import jobsListed from '../../Datasets/jobsListed.json';
/*======================*/

/*===== [Icons] =====*/
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LinearProgress from '@mui/material/LinearProgress';
/*======================*/

import { useState, useEffect } from "react";

const CareersPage = () => {
    const { jobCat } = useParams();
    const [jobsListed, setjobsListed] = useState(null);
    const [jobCategories, setJobCategories] = useState(null);
    const [loading, setLoading] = useState(true); 

    const getJobListings = async (catId = "") => {
        setLoading(true);

        let route = `careers`;
        if(catId !== "") {
            route = `careers/${catId}`;
        }
    
        let params = {
            "route": route,
        };

        try {
            const response = await getData(params);
            setjobsListed(response);
        } catch (error) {
            console.error('Error in handleGet:', error);
        } finally {
            setLoading(false); // Stop loading once the data is fetched
        }
      };

      const getJobCategories = async() => {
        let params = {
            "route":"job_categories"
        }
        
        try {
            const response = await getData(params);
            setJobCategories(response);
        } catch (error) {
            console.error('Error in handleGet:', error);
        }
      }

    useEffect(() => {
        getJobListings(jobCat);
        getJobCategories();
    }, [jobCat]);

    return(
        <Grid2 className={styles.mainContentGrid}>
            <Grid2>
                <Typography variant="h5">
                    We are Hiring!
                </Typography>
                <Typography variant="h4" className="h4Styling" mt={1}>
                    Be Apart Of Our Mission
                </Typography>
                <Typography variant="body1" mt={1}>
                    We're looking for passionate people to join us in our mission. We value clear communication, full ownership, responsibilty & critical thinking.
                </Typography>
            </Grid2>

            <Grid2 className={styles.jobCategoriesDiv}>
                <Button
                    component={Link}
                    to={`/careers`}
                    onClick={() => getJobListings()}
                >
                    <Chip 
                        label="All"
                        variant={jobCat === undefined ? "filled" : "outlined"}
                        sx={ jobCat === undefined ? {backgroundColor: "#D6C09D"}: ''}
                    />
                </Button>
                
                {(jobCategories && jobCategories.length > 0) ? (
                    jobCategories.map((category, index) => (
                        
                        <Button
                            component={Link}
                            to={`/careers/${category.id}`}
                            key={index}
                            onClick={() => getJobListings(category.id)}
                        >
                            <Chip 
                                key={index} 
                                label={category.name} 
                                variant={jobCat == category.id ? "filled" : "outlined"}
                                sx={ jobCat == category.id ? {backgroundColor: "#D6C09D"}: ''}
                            />
                        </Button>
                        
                    ))
                ) : (
                    <Typography>
                        No Data To Display
                    </Typography>
                )}
            </Grid2>

            <Divider />

            <Grid2 className={styles.jobsListedDiv}>
                {loading ? ( 
                    <>
                        <Typography>
                            Standby while we fetch the latest career opportunities.
                        </Typography>
                        <LinearProgress />
                    </>
                ) : (
                    (jobsListed && jobsListed.length > 0) ? (
                        jobsListed.map((jobDetails, index) => (
                            <Grid2 key={index} className={styles.jobDetails}>
                                <Typography variant="h4">
                                    {jobDetails.job_title}
                                </Typography>

                                <Typography variant="body1" sx={{ fontWeight: "200" }}>
                                    {jobDetails.job_description}
                                </Typography>

                                {(jobDetails.tags && jobDetails.tags.length > 0) && (
                                    jobDetails.tags.map((tag, tagIndex) => (
                                        <Chip
                                            key={tagIndex}
                                            label={tag.name}
                                            variant="outlined"
                                            className={styles.tagChip}
                                            sx={{ ml: tagIndex === 0 ? 0 : 1 }}
                                        />
                                    ))
                                )}

                                <Button className={styles.applyButton} endIcon={<ArrowForwardIcon /> }>
                                    Apply
                                </Button>
                                <Divider />
                            </Grid2>
                        ))
                    ) : (
                        <Typography>
                            No Jobs To Display
                        </Typography>
                    )
                )}
            </Grid2>

        </Grid2>
    );

}

export default CareersPage;