
import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

import { useEffect, useState } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { IconButton} from '@mui/material';

import { Link as ScrollLink } from 'react-scroll';

/*===== [React-Router-Dom] =====*/
import { Link as RouterLink }  from 'react-router-dom';
/*==============================*/

export default function SwipeableTemporaryDrawer() {
  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if ( event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')){
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
        sx={{
            width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
        }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
    >

        <List>
            {['Home', 'Mission', 'Services', 'Contact', 'Careers'].map((page) => {

                return (
                    <ListItem key={page} disablePadding>
                        <ListItemButton>
                            {page === 'Careers' ? (
                                <Button
                                    component={RouterLink}
                                    to="/careers"
                                    key={page}
                                    color="inherit"
                                >
                                    {page}
                                </Button>
                            ) : page === 'Home' ? (
                                <Button
                                    component={RouterLink}
                                    to="/"
                                    key={page}
                                    color="inherit"
                                >
                                    {page}
                                </Button>
                            ) : (
                                // Render ScrollLink for other pages
                                <ScrollLink
                                    to={page}
                                    smooth={true}
                                    duration={500}
                                    offset={-60}
                                    style={{ textDecoration: 'none', width: '100%' }}
                                >
                                    <Button color="inherit">
                                        {page}
                                    </Button>
                                </ScrollLink>
                            )}
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    </Box>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
            <IconButton
                size="large"
                aria-label="user acount and other pages/settings"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer(anchor, true)}
                color="inherit"
            >
                <MenuIcon sx={{ fill: "#C8B293", fontSize: "35px" }} />
            </IconButton>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
